import { useCallback } from 'react'
import { atom, selector, useRecoilState } from 'recoil'

export interface AppReceivedInfo {
	pushToken?: string
	isPushNoti?: boolean
}
export interface AppInfo extends AppReceivedInfo {
	DType: 'I' | 'A' | 'W'
	appVersion?: string
	adminVersion?: string
}
export interface IsAppState {
	isApp?: boolean
	appInfo?: AppInfo
}

export const isAppState = atom<IsAppState>({
	key: '#isApp',
	default: {
		isApp: false,
		appInfo: undefined,
	},
})

export const useIsAppControl = () => {
	const [state, setState] = useRecoilState(isAppState)

	const setIsApp = useCallback(
		(value?: IsAppState) => {
			if (value) {
				setState({ ...value })
			} else {
				setState({ isApp: false })
			}
		},
		[setState],
	)
	const setAppInfo = useCallback(
		(value?: AppReceivedInfo) => {
			setState((prev) => ({
				isApp: prev.isApp,
				...(prev.appInfo
					? {
							appInfo: {
								...prev.appInfo,
								...value,
							},
					  }
					: {}),
			}))
		},
		[setState],
	)

	return { isApp: state.isApp, appInfo: state.appInfo, setIsApp, setAppInfo }
}

export const appInterface = selector({
	key: '#appInterface',
	get: ({ get }) => {
		const appState = get(isAppState)
		if (appState?.appInfo?.DType === 'I') {
			return (postMessage: NativePostMessage) =>
				window?.webkit?.messageHandlers?.ios?.postMessage?.(postMessage)
		}
		if (appState?.appInfo?.DType === 'A') {
			return (postMessage: NativePostMessage) => {
				window?.android?.postMessage?.(JSON.stringify(postMessage))
			}
		}
		return (postMessage: NativePostMessage) => undefined
	},
})
