import { useLayoutEffect } from 'react'
import { usePathname } from 'next/navigation'
import { useRecoilValue } from 'recoil'
import { appInterface } from '@store/isApp'

export const useDarkModeControl = () => {
	const nativeEvent = useRecoilValue(appInterface)
	const pathname = usePathname()
	const fixedDarkMode: boolean =
		(!pathname.includes('/videoNews') && pathname.includes('/video')) ||
		pathname.includes('/shorts')
	useLayoutEffect(() => {
		if (nativeEvent) {
			nativeEvent({
				key: 'dimmed',
				value: {
					hexcode: fixedDarkMode ? '242424' : 'ffffff',
					isDark: fixedDarkMode,
				},
			})
		}
	}, [fixedDarkMode, nativeEvent])
	return {
		darkMode: fixedDarkMode,
	}
}
