const color = {
	colBlack: '#242424',
	colWhite: '#FFFFFF',
	colGray1: '#F6F6F6',
	colGray2: '#F2F2F2',
	colGray3: '#E4E4E4',
	colGray4: '#999999',
	colGray5: '#777777',
	colDarkBlue: '#052D5A',
	colBlue: '#1163C3',
	colLightBlue: '#E1EBFA',
	colPointBlue: '#0157AA',
	colDarkmodePointBlue: '#0072E1',
	colMintBlue: '#007FB7',
	colLiveRed: '#EE0007',
	colExclusiveRed: '#B60016',
	colPurple: '#630FA6',
	colPink: '#ED0063',
	shadow: {},
	shade: 'rgba(0, 0, 0, 0.50)',
	gradient: {},
} as const

export default color
