'use client'

import LoadingSpinner from '@components/layout/Loading/Spinner'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

const Loading = () => {
	const pathname = usePathname()
	const isShorts = pathname.includes('/shorts')
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return isShorts ? null : <LoadingSpinner />
}

export default Loading
